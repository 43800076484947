import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { contentResource } from "../../util/resources";
export const namespaceHelpers = createNamespacedHelpers("authentication");

export const types = {
  mutations: {
    REQUEST: "authentication__REQUEST",
    FAILURE: "authentication__FAILURE",
    SUCCESS: "authentication__SUCCESS",
    SUCCESS_EDIT: "authentication__SUCCESS_EDIT",
    SUCCESS_DELETE: "authentication__SUCCESS_DELETE",
    SUCCESS_UPLOAD: "authentication__SUCCESS_UPLOAD",
    DELETE_INPUT_CHANNEL: "authentication__DELETE_INPUT_CHANNEL",
  },
};

export const authentication = {
  namespaced: true,
  state: {
    isLoading: false,
    isLoaded: false,
    data: [],
    error: {},
  },
  mutations: {
    [types.mutations.REQUEST](state) {
      state.isLoading = true;
      state.isLoaded = false;
      state.error = {};
    },

    [types.mutations.FAILURE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = payload.response.data;
    },

    [types.mutations.SUCCESS](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      state.data = payload.data;
    },

    [types.mutations.SUCCESS_UPLOAD](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      Vue.set(state.data, state.data.length, payload.data);
    },

    [types.mutations.SUCCESS_EDIT](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      const index = state.data.findIndex((val) => val.id === payload.data.id);
      Vue.set(state.data, index, payload.data);
    },

    [types.mutations.SUCCESS_DELETE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      const index = state.data.findIndex((val) => val.id === payload.id);
      Vue.delete(state.data, index);
    },
  },
  getters: {
    authentications: (state) => state.data,
    authenticationById: (state) => (id) => state.data.find((auth) => auth.id === id),
  },
  actions: {
    async load({ commit }) {
      try {
        const res = await contentResource.get("/authentications");
        commit(types.mutations.SUCCESS, res);
      } catch (err) {
        commit(types.mutations.FAILURE, err);
      }
    },

    async save({ commit }, authentication) {
      try {
        const res = await contentResource.post("/authentications", authentication);
        commit(types.mutations.SUCCESS_UPLOAD, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },

    async edit({ commit }, authentication) {
      try {
        const res = await contentResource.put(`/authentications/${authentication.id}`, authentication);
        commit(types.mutations.SUCCESS_EDIT, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },

    async delete({ commit }, authentication) {
      try {
        const res = await contentResource.delete(`/authentications/${authentication.id}`);
        commit(types.mutations.SUCCESS_DELETE, authentication);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },
  },
};
