<template>
  <v-layout align-end justify-end>
    <v-dialog v-model="dialog" max-width="500px" @input="close">
      <v-form ref="form" v-model="valid" lazy-validation>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" fab fixed right bottom :disabled="isLoading">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline" v-if="template != null">Edit Template</span>
            <span class="headline" v-else>Create new Template</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              data-cy="template-dialog-name-field"
              v-model="currentName"
              :rules="nameRules"
              label="Name"
              required
            ></v-text-field>
            <v-text-field
              v-if="template == null"
              label="Slide Name"
              v-model="slideName"
              :rules="slideNameRules"
            ></v-text-field>
            <v-autocomplete
              label="Output Channels"
              multiple
              v-model="selectedOutputChannels"
              :items="outputChannels"
              chips
              item-value="id"
              item-text="name"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              text
              @click="
                dialog = false;
                $emit('close', false);
              "
            >
              Close
            </v-btn>
            <v-btn
              color="accent"
              type="submit"
              text
              @click="submit"
              :disabled="!valid"
              data-cy="template-dialog-save-button"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-layout>
</template>

<script>
import Vue from "vue";

import { namespaceHelpers } from "@/store/modules/templates";
import { namespaceHelpers as outputChannelHelpers } from "@/store/modules/outputChannels";

export default Vue.extend({
  props: ["isLoading", "template"],

  data() {
    return {
      dialog: false,
      valid: true,
      currentName: "",
      slideName: "Slide 1",
      nameRules: [(v) => !!v || "Template name is required"],
      slideNameRules: [(v) => !!v || "Slide name is required"],
      selectedOutputChannels: [],
    };
  },

  methods: {
    ...namespaceHelpers.mapActions({
      save: "save",
      editTemplate: "editTemplate",
    }),

    show() {
      if (!this.template) {
        this.currentName = "";
        this.valid = true;
        this.selectedOutputChannels = [];
        if (this.$refs.form) {
          this.$refs.form.reset();
        }
      }
      this.dialog = true;
    },

    reset() {
      this.currentName = "";
      this.valid = true;
      this.$refs.form.reset();
      this.selectedOutputChannels = [];
      this.$emit("close");
    },

    close() {
      this.reset();
      // this.$emit('close', event);
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        if (this.template != null) {
          await this.editTemplate({
            id: this.template.id,
            name: this.currentName,
            outputChannelIds: [...this.selectedOutputChannels].filter((entry) =>
              this.outputChannels.find((channel) => channel.id === entry)
            ),
          }).then(() => this.reset());
        } else {
          const template = {
            name: this.currentName,
            outputChannelIds: [...this.selectedOutputChannels],
            slides: [
              {
                id: Date.now().toString(36) + Math.random().toString(36).substring(2, 5),
                duration: 100,
                name: this.slideName,
                // TODO set/get last set width/height
                width: 1920,
                height: 1080,
                components: [],
              },
            ],
          };
          await this.save(template).then(() => {
            this.reset();
          });
        }
      }
    },
  },

  computed: {
    ...outputChannelHelpers.mapGetters(["outputChannels"]),
  },
  watch: {
    template(val) {
      if (val) {
        this.currentName = val.name;
        this.selectedOutputChannels = val.outputChannelIds;
      }
    },
  },
});
</script>

<style></style>
